import {AuthUser} from "../../models/authUser";
import {ExternalAppInfo} from "../../models/externalAppInfo";
import jwt from 'jwt-decode';

export interface AuthState {
  loggedIn: boolean,
  authToken: string | null,
  user: AuthUser | null,
  externalApp: ExternalAppInfo | null
}

export type Action = {
  type: "auth/LOGGED_IN",
  authToken: string
} | {
  type: "auth/LOGGED_OUT"
} | {
  type: "auth/GOT_EXTERNAL_APP_INFO",
  data: ExternalAppInfo
}

function getUserFromToken(authToken: string) {
  if (authToken == null) {
    console.log("passed null authtoken");
    return null;
  }
  let data = jwt(authToken) as any;

  return {
    username: data["user.username"],
    name: data["user.name"],
    permissions: JSON.parse(data["user.permissions"])
  };
}


const getInitialState = (): AuthState => {
  let authToken = sessionStorage.getItem("authToken") ?? localStorage.getItem("authToken")

  return {
    loggedIn: !!authToken,
    authToken: authToken,
    user: authToken ? getUserFromToken(authToken) : null,
    externalApp: null
  }
}
const initialState: AuthState = getInitialState();

export default function reducer(state: AuthState = initialState, action: Action): AuthState {
  switch (action.type) {
    case "auth/LOGGED_IN":
      return {...state, loggedIn: true, user: getUserFromToken(action.authToken), authToken: action.authToken}
    case "auth/LOGGED_OUT":
      return {...state, loggedIn: false, user: null, authToken: null}
    case "auth/GOT_EXTERNAL_APP_INFO":
      return {...state, externalApp: action.data}
    default:
      return state;
  }
}

export function loggedIn(authToken: string): Action {
  return {type: "auth/LOGGED_IN", authToken: authToken};
}

export function loggedOut(): Action {
  return {type: "auth/LOGGED_OUT"};
}

export function gotExternalAppInfo(data: ExternalAppInfo): Action {
  return {type: "auth/GOT_EXTERNAL_APP_INFO", data: data}
}

import React from 'react';
import './App.css';
import {Header} from '../shared/Header';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Downloader, Home, Login, Users} from "../pages";
import * as qs from "query-string";
import {PageWrapper} from '../shared/PageWrapper';


function App() {
  const isAuth = window.location.host.startsWith("auth.");
  const externalApp = qs.parse(window.location.search)["externalApp"] as string;
  const cancelPath = qs.parse(window.location.search)["cancelPath"] as string;
  const successPath = qs.parse(window.location.search)["successPath"] as string;

  return (
    <BrowserRouter>
      <Header/>
      <Switch>
        {isAuth ? (
          <>
            <Route path="/login"><Login mode="login" isExternal={true} external={externalApp} cancelPath={cancelPath} successPath={successPath}/></Route>
            <Route path="/register"><Login mode="register" isExternal={true} external={externalApp} cancelPath={cancelPath} successPath={successPath}/></Route>
            <Route path="/forgotPassword"><Login mode="forgotPassword" isExternal={true} external={externalApp} cancelPath={cancelPath} successPath={successPath}/></Route>
            <Route path="/logout"><Login mode="logout" isExternal={true} external={externalApp} cancelPath={cancelPath} successPath={successPath}/></Route>
          </>
        ) : (
          <>
            <Route path="/login"><Login mode="login"/></Route>
            <Route path="/register"><Login mode="register"/></Route>
            <Route path="/forgotPassword"><Login mode="forgotPassword"/></Route>
            <Route path="/logout"><Login mode="logout"/></Route>

            <Route path="/admin">
              <PageWrapper>
                <Route path="/admin/users">
                  <Users/>
                </Route>
              </PageWrapper>
            </Route>

            <Route path="/downloader">
              <PageWrapper>
                <Downloader/>
              </PageWrapper>
            </Route>
            <Route exact path="/"><Home/></Route>
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}

export default (App);

import axios, {AxiosResponse} from "axios";
import config from "../config";
import {ApiError} from "../models/apiError";
import store from "../store/store";

function wrap<T>(request: Promise<AxiosResponse<any>>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        request.then(result => {
            if (result.data?.success === false) {
                reject(result.data.error);
                return;
            }

            if (result.data?.success === true && result.data.result !== undefined) {
                resolve(result.data.result)
                return;
            }

            resolve(result.data);
        }).catch(reason => {
            reject({
                message: reason.response?.data?.error ?? reason.message,
                statusCode: reason.response?.status
            } as ApiError);
        })
    });
}

function getHeaders() {
    let headers: any = {};

    let apiToken = store.getState().AuthState.authToken;
    if (apiToken) headers["Authorization"] = "Bearer " + apiToken;

    return headers;
}

export const ApiService = {
    post: <T>(path: string, body: any): Promise<T> => wrap<T>(axios.post(config.apiUrl + path, body, {headers: getHeaders()})),
    get: <T>(path: string): Promise<T> => wrap<T>(axios.get(config.apiUrl + path, {headers: getHeaders()})),
}

import {ApiService} from "./ApiService";
import {Dispatch} from "react";
import {gotExternalAppInfo, loggedIn, loggedOut} from "../store/ducks/auth";
import {ExternalAppInfo} from "../models/externalAppInfo";
import config from "../config";
import {AuthUser} from "../models/authUser";

export const AuthService = {
  logIn: (dispatch: Dispatch<any>, username: string, password: string, rememberMe: boolean): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      ApiService.post<{ token: string }>("/auth/login", {
        username: username,
        password: password,
        rememberMe: rememberMe
      })
        .then(result => {
          let storage = rememberMe ? localStorage : sessionStorage;
          storage.setItem("authToken", result.token)

          dispatch(loggedIn(result.token));
          resolve();
        }).catch(reject)
    })
  },
  logOut: (dispatch: Dispatch<any>): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      localStorage.clear();
      sessionStorage.clear();

      // probably also call server or whatever

      dispatch(loggedOut());
      resolve();
    })
  },
  register: (dispatch: Dispatch<any>, name: string, username: string, password: string): Promise<void> => {
    return ApiService.post<void>("/auth/register", {
      name: name,
      username: username,
      password: password
    }).then(() => {
      AuthService.logIn(dispatch, username, password, false).then(r => {
      });
    });
  },
  getExternalAppInfo(dispatch: Dispatch<any>, externalAppName: string) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get<ExternalAppInfo>("/auth/externalAppInfo/" + externalAppName).then(result => {
        let r = {...result};
        if (r.hasImage) {
          r.imageUrl = config.apiUrl + r.imageUrl;
        }
        dispatch(gotExternalAppInfo(r))
        resolve();
      }).catch(reject);
    })
  },
  hasPermission(user: AuthUser | null, requiredPermission: string) {
    if (user === null) return false;
    const segments = requiredPermission.split(".");

    if (user.permissions == null) return false;

    let activePermissions = user.permissions;

    for (let i = 0; i < segments.length; i++) {
      let segment = segments[i];

      activePermissions = activePermissions.filter(p => p.startsWith(segment)).map(p => {
        const sp = p.split(".", 2);
        return sp.length === 2 ? sp[1] : "";
      });

      if (activePermissions.length === 0)
        return false;

      if (activePermissions.filter(p => p === "*" || p === '').length > 0)
        return true;
    }

    return true;
  }
}

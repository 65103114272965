import {ApiService} from "./ApiService";

export type DownloadStatus = 'accepted' | 'queued' | 'downloading' | 'done' | 'error';

export interface DownloadRequest {
  id: string,
  token: string,
  queuePosition: number | null,
  status: DownloadStatus,
  progress: number | null,
  error: string | null,
  url: string,
  filename: string | null,
  size: number | null,
  speed: number | null,
  created: Date
}

export type DownloadStatusUpdate = AcceptedStatusUpdate | QueuedStatusUpdate | DownloadingStatusUpdate | DoneStatusUpdate | ErrorStatusUpdate;

interface AcceptedStatusUpdate {
  requestId: string;
  status: 'accepted';
  payload: {
    token: string;
    url: string;
    created: Date;
    queuePosition: number;
  }
}

interface QueuedStatusUpdate {
  requestId: string;
  status: 'queued';
  payload: {
    queuePosition: number;
  }
}

interface DownloadingStatusUpdate {
  requestId: string;
  status: 'downloading';
  payload: {
    filename: string;
    size: number | null;
    progress: number;
    speed: number;
  }
}

interface DoneStatusUpdate {
  requestId: string;
  status: 'done';
  payload: {
    filename: string;
    size: number;
  }
}

interface ErrorStatusUpdate {
  requestId: string;
  status: 'error';
  payload: {
    error: string;
  }
}

export const DownloaderService = {
  request: (url: string): Promise<DownloadStatusUpdate> => {
    return new Promise<DownloadStatusUpdate>((resolve, reject) => {
      ApiService.post<DownloadStatusUpdate>("/downloader/request", {url: url}).then(resolve).catch(reject);
    })
  },
  getStatus: (requests: {id: string, token: string}[]): Promise<DownloadStatusUpdate[]> => {
    return new Promise<DownloadStatusUpdate[]>(((resolve, reject) => {
      ApiService.post<DownloadStatusUpdate[]>(`/downloader/status`, requests).then(resolve).catch(reject);
    }));
  },
  cancel: (requestId: string, token: string): Promise<DownloadStatusUpdate> => {
    return new Promise<DownloadStatusUpdate>(((resolve, reject) => {
      ApiService.post<DownloadStatusUpdate>(`/downloader/cancel/${requestId}`, {token: token}).then(resolve).catch(reject);
    }));
  }
}

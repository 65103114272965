import React, {PropsWithChildren} from "react";
import {Breadcrumbs} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

export function PageWrapper(props: PropsWithChildren<{}>) {
  const {t} = useTranslation();
  const location = useLocation();

  let parts = location.pathname.split('/').filter(p => p);

  for (let i = parts.length - 1; i >= 0; i--) {
    for (let j = i + 1; j < parts.length; j++) {
      parts[j] = parts[i] + "_" + parts[j];
    }
  }

  let title = '';

  if (parts.length > 0) {
    title = parts[parts.length - 1];
    parts.pop();
  }


  return (
    <div className="container pt-4">
      <div className="row">
        <div className="col-6">
          <Breadcrumbs>
            {parts.map(p => {
              return <span key={p}>{t("pages." + p)}</span>;
            })}
          </Breadcrumbs>
        </div>
      </div>
      <h3>{t("pages." + title)}</h3>
      {props.children}
    </div>
  )
}

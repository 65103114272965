import {Link, useHistory} from "react-router-dom";
import React, {CSSProperties} from "react";
import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function RouteButton(props: { path: string, className: string, text: string, icon?: IconDefinition, type?: 'submit' | 'reset' | 'button' | undefined, style?: CSSProperties | undefined }) {
    const history = useHistory();
    return <button className={props.className}
                   onClick={() => history.push(props.path)}
                   type={props.type}
                   style={props.style}>
        {props.icon ? (
            <FontAwesomeIcon icon={props.icon}/>
        ) : null}
        <span>{props.text}</span>
    </button>;
}

export function RouteLink(props: React.PropsWithChildren<{ path: string, className: string }>) {
    return <Link to={props.path} className={props.className}>{props.children}</Link>
}

import { User } from "../models/user";
import {ApiService} from "./ApiService";

export const UsersService = {
  getUsers: () => {
    return new Promise<User[]>((resolve, reject) => {
      ApiService.get<User[]>('/auth/admin/users').then(resolve).catch(reject);
    });
  }
}

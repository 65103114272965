import React from "react";
import {useSelector} from "react-redux";
import {RouteButton} from "../RouteButton";
import {Store} from "../../../store/reducer";
import {useTranslation} from "react-i18next";


export function HeaderUserData() {
  const {loggedIn, user} = useSelector((state: Store) => state.AuthState);
  const {t} = useTranslation();

  return (
    <div>
      <span className="m-1">{user == null ? t("header.greet_user_unknown") : t("header.greet_user", {name: user.name})}</span>
      {loggedIn ? (
        <>
          <RouteButton path="/logout" className="m-1 btn btn-outline-primary infinitools" text={t("header.log_out")}/>
        </>
      ) : (
        <>
          <RouteButton path="/login" className="m-1 btn btn-outline-primary infinitools" text={t("header.log_in")}/>
          <RouteButton path="/register" className="m-1 btn btn-outline-primary infinitools" text={t("header.register")}/>
        </>
      )}
    </div>
  );
}

import logo from "../../../logo.svg"
import React, {useEffect, useState} from "react";
import {faEnvelope, faSignInAlt, faTimes, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import {RouteLink} from "../../shared/RouteButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {AuthService} from "../../../services/AuthService";
import "./Login.css"
import {useHistory} from "react-router-dom";
import * as qs from "query-string";
import {Store} from "../../../store/reducer";
import styled, {ThemeProvider} from "styled-components";
import {useTranslation} from "react-i18next";
import {ApiError} from "../../../models/apiError";

const Button = styled.button`
  color: #${props => props.theme.main};
  border-color: #${props => props.theme.main} !important;

  :hover {
    background-color: #${props => props.theme.main};
    color: #ffffff;
  }
`

type Modes = 'login' | 'register' | 'forgotPassword' | 'logout';
type Props =
  { mode: Modes, isExternal?: false, external?: null, successPath?: null, cancelPath?: null }
  | { mode: Modes, isExternal: true, external: string, successPath: string, cancelPath: string }

export function Login(props: Props) {
  const {t} = useTranslation();

  const isLogin = props.mode === 'login';
  const isRegister = props.mode === 'register';
  const isForgotPassword = props.mode === 'forgotPassword';
  const isLogout = props.mode === 'logout';

  const needsUsername = isLogin || isRegister || isForgotPassword;
  const needsPassword = isLogin || isRegister;

  const title =
    isLogin ? t("login.welcome_back") :
      isRegister ? t("login.welcome") :
        isForgotPassword ? t("login.recover_your_password") :
          t("login.logging_you_out");

  const dispatch = useDispatch();
  const history = useHistory();
  const [name, setName] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initLoading, setInitLoading] = useState(props.isExternal)
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const {externalApp} = useSelector((state: Store) => state.AuthState);
  const {loggedIn, authToken} = useSelector((state: Store) => state.AuthState);

  const justRegistered = !!qs.parse(window.location.search)["registered"];

  let queryData = "";

  const isAuth = window.location.host.startsWith("auth.") && props.isExternal;

  if (isAuth) {
    queryData = `?externalApp=${encodeURIComponent(props.external)}&cancelPath=${encodeURIComponent(props.cancelPath)}&successPath=${encodeURIComponent(props.successPath)}`;
  }

  // remove error if page or something else changed
  useEffect(() => {
    setError("");
    setMessage("");
  }, [props.mode, name, username, password, confirmPassword, rememberMe])

  useEffect(() => {
    if (!isLogout) return;

    AuthService.logOut(dispatch).then(() => {
      if (props.isExternal) {
        window.location.assign(`${props.successPath}?loggedOut=1`);
      } else {
        history.push("");
      }
    });

  }, [isLogout, dispatch, externalApp, history, props.cancelPath, props.isExternal, props.successPath])

  // if is external, do some stuff
  useEffect(() => {
    if (!props.isExternal) return;

    // is external but just registered?
    if (justRegistered) return;

    // check if is already logged in
    if (loggedIn) {
      // is logged in, go to success immediately

      window.location.assign(`${props.successPath}${(props.successPath).includes("?") ? '&' : '?'}token=${authToken}`);
      return;
    }

    // fail callback
    let failExternalApp = (e: any) => {
      setError(e);
      setTimeout(() => window.location.reload(), 3000);
    }

    // get external app info
    AuthService.getExternalAppInfo(dispatch, props.external as string).then(() => {
      setInitLoading(false);
    }).catch(failExternalApp);
  }, [authToken, dispatch, props, loggedIn, justRegistered])

  // not external or just registered
  useEffect(() => {
    if (props.isExternal || justRegistered) return;

    if (loggedIn) {
      history.push("/");
      return;
    }
  }, [history, justRegistered, loggedIn, props.isExternal])

  useEffect(() => {
    if (justRegistered)
      setMessage("login.register_success")
  }, [justRegistered])

  function formSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (isLogin) {
      setLoading(true);
      AuthService.logIn(dispatch, username, password, rememberMe).then(() => {
      }).catch((error: ApiError) => {
        setError(error.message)
      }).finally(() => {
        setLoading(false);
      })
    }

    if (isRegister) {
      if (password !== confirmPassword) {
        setError("login.password_mismatch");
        return;
      }

      setLoading(true);
      AuthService.register(dispatch, name, username, password).then(() => {
      }).catch((error: ApiError) => {
        setError(error.message)
      }).finally(() => {
        setLoading(false);
      })
    }

    if (isForgotPassword) {
      // setLoading(false);
      setError("Not implemented yet");
    }
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ThemeProvider theme={{main: externalApp?.mainColor || "f0a504"}}>
            {initLoading ? ("") : (
              <div className="form-login-container">
                <form className="form-login" onSubmit={e => formSubmit(e)}>
                  <div className="header">
                    {props.isExternal ?
                      (externalApp?.hasImage ?
                          (<img src={externalApp.imageUrl} alt={externalApp.title}/>) :
                          (externalApp?.title)
                      ) :
                      (<img src={logo} alt="Infinitools"/>)
                    }
                    <h1 className="h3 m-3 font-weight-normal" style={{textAlign: "center"}}>{title}</h1>
                  </div>
                  {isLogout ? null : (<>
                    {isRegister ? (
                      <input value={name}
                             onChange={e => setName(e.target.value)}
                             className="form-control"
                             type="text"
                             placeholder={t("login.placeholder_name")}/>
                    ) : null}
                    {needsUsername ? (
                      <input value={username}
                             onChange={e => setUsername(e.target.value)}
                             className="form-control"
                             type="text"
                             placeholder={t("login.placeholder_username")}/>
                    ) : null}
                    {needsPassword ? (
                      <input value={password}
                             onChange={e => setPassword(e.target.value)}
                             className="form-control"
                             type="password"
                             placeholder={t("login.placeholder_password")}/>
                    ) : null}
                    {isRegister ? (
                      <input value={confirmPassword}
                             onChange={e => setConfirmPassword(e.target.value)}
                             className="form-control"
                             type="password"
                             placeholder={t("login.placeholder_confirm_password")}/>
                    ) : null}
                    {isLogin ? (
                      <div className="form-check form-check-inline">
                        <input className="form-check-input"
                               checked={rememberMe}
                               onChange={e => setRememberMe(e.target.checked)}
                               type="checkbox"
                               id="rememberMe"/>
                        <label className="form-check-label" htmlFor="rememberMe">{t("login.remember_me")}</label>
                      </div>
                    ) : null}
                    {error ? (<div className="alert alert-danger">{t("error." + error)}</div>) : null}
                    {message ? (<div className="alert alert-success">{message}</div>) : null}
                    <Button className={`btn btn-outline-primary mt-2 w-100 ${loading ? "disabled" : ""}`}>
                      <FontAwesomeIcon icon={isLogin ? faSignInAlt : isRegister ? faUserPlus : isForgotPassword ? faEnvelope : faSignInAlt}/>
                      <span>{isLogin ? t("login.log_in") : isRegister ? t("login.register") : isForgotPassword ? t("login.recover_password") : ""}</span>
                    </Button>
                    {props.isExternal ? (
                      <Button className={`btn btn-outline-primary mt-2 w-100 ${loading ? "disabled" : ""}`} onClick={() => window.location.assign(props.cancelPath)}>
                        <FontAwesomeIcon icon={faTimes}/>
                        <span>{t("login.cancel")}</span>
                      </Button>
                    ) : null}
                    {isLogin ? (
                      <div className="text-end">
                        <RouteLink path={"/forgotPassword" + queryData} className="text-muted">{t("login.forgot_password")}</RouteLink>
                      </div>
                    ) : null}
                    {/*<span className="text-muted">or</span>*/}
                    <hr/>
                    <div className="text-center">
                      <span className="text-muted">{isLogin ? t("login.no_account") : isRegister ? t("login.have_account") : isForgotPassword ? t("login.remember_password") : ""}</span>
                    </div>
                    <Button className="btn btn-outline-primary w-100" onClick={() => history.push((isLogin ? "register" : "login") + queryData)} type="button">
                      <FontAwesomeIcon icon={isLogin ? faUserPlus : faSignInAlt}/>
                      <span>{isLogin ? t("login.register") : t("login.log_in")}</span>
                    </Button>
                  </>)}
                </form>
              </div>
            )}
          </ThemeProvider>
        </div>
      </div>
    </div>
  );

}







import {
  DataGrid,
  GridCallbackDetails,
  GridCellEditCommitParams,
  GridCellValue,
  GridColDef,
  GridValueFormatterParams,
  MuiEvent
} from "@mui/x-data-grid";
import './Users.css';
import {SyntheticEvent, useEffect, useState} from "react";
import {UsersService} from "../../../../services/UsersService";
import {User} from "../../../../models/user";
import {ApiError} from "../../../../models/apiError";
import {useTranslation} from "react-i18next";
import {Permission} from "../../../../models/permission";
import {InlineMonospace} from "../../../shared/InlineMonospace";
import {Checkbox, FormControlLabel} from "@mui/material";
import {Delete} from "@mui/icons-material";

const gridColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 250,
    sortable: true,
    editable: true
  },
  {
    field: "username",
    headerName: "Username",
    width: 150,
    sortable: true,
    renderCell: params => <InlineMonospace text={params.value}/>
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    sortable: true
  },
  {
    field: "creationDate",
    headerName: "Creation Date",
    sortable: true,
    width: 180,
    valueFormatter: (params: GridValueFormatterParams): GridCellValue => new Date(params.value as string).toLocaleString()
  },
  {
    field: "permissions",
    headerName: "Permissions",
    width: 300,
    sortable: false,
    renderCell: params => <InlineMonospace text={(params.value as Permission[]).map(p => p.name).join(', ')}/>
  },
]

export function Users() {
  const {t} = useTranslation();
  const [users, setUsers] = useState([] as User[])
  const [error, setError] = useState(null as ApiError | null);
  const [loading, setLoading] = useState(false);
  const [selectionEnabled, setSelectionEnabled] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  useEffect(() => {
    setSelectionEnabled(false);
  }, [])

  useEffect(() => {
    setLoading(true);
    UsersService.getUsers().then(setUsers).catch(setError).finally(() => setLoading(false));
  }, [])

  function cellEditCommit(params: GridCellEditCommitParams, event: MuiEvent<SyntheticEvent>, details: GridCallbackDetails) {
    let user = users.find(value => value.id === params.id);
    console.log(user);
  }

  return (
    <>
      {loading ? (
        <span>Loading</span>
      ) : error ? (
        <span>{t("error." + error.statusCode)}</span>
      ) : (
        <>
          <div className="col-10 grid-height">
            <DataGrid
              columns={gridColumns}
              rows={users}
              checkboxSelection={selectionEnabled}
              isRowSelectable={() => selectionEnabled}
              pageSize={50}
              rowsPerPageOptions={[50]}
              onCellEditCommit={cellEditCommit}
            />
          </div>
          <div className="col-2">
            <FormControlLabel control={<Checkbox onChange={event => setSelectionEnabled(event.target.checked)} checked={selectionEnabled}/>} label={t("admin_users.selection_enabled")}/>
            <FormControlLabel control={<Checkbox onChange={event => setShowDeleted(event.target.checked)} checked={showDeleted} icon={<Delete/>}/>} label={t("admin_users.show_deleted")}/>


          </div>
        </>
      )}
    </>
  )
}

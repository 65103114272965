import {Controller, useForm} from "react-hook-form";
import {Box, Button, LinearProgress, LinearProgressProps, TextField, Typography} from "@mui/material";
import {LoadingButton} from '@mui/lab';
import {useTranslation} from "react-i18next";
import {DownloaderService, DownloadRequest, DownloadStatusUpdate} from "../../../services/DownloaderService";
import {useCallback, useEffect, useState} from "react";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import {faArrowDown, faCheck, faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";


export function Downloader() {
  const {t} = useTranslation();
  const {handleSubmit, control, formState} = useForm({mode: 'onChange'});

  const [requests, setRequests] = useState([] as DownloadRequest[]);
  const [gettingStatus, setGettingStatus] = useState(false);

  function requestCancel(request: DownloadRequest) {
    DownloaderService.cancel(request.id, request.token).then(handleStatusUpdate);
  }

  function fetchAllStatus() {
    // if (gettingStatus) {
    //   return;
    // }

    // setGettingStatus(true);

    if (requests.length > 0) {
      DownloaderService.getStatus(requests.map(r => ({id: r.id, token: r.token}))).then(statuses => {
        // setGettingStatus(false);
        statuses.forEach(handleStatusUpdateCallback);
      });
    }
  }

  function download(request: DownloadRequest) {
    // download file
  }

  const handleStatusUpdateCallback = useCallback(handleStatusUpdate, [requests]);
  function handleStatusUpdate(statusUpdate: DownloadStatusUpdate) {
    let request = requests.find(r => r.id === statusUpdate.requestId) as DownloadRequest;

    let newReq = false;

    if (statusUpdate.status === "accepted") {
      request = {
        id: statusUpdate.requestId,
        token: statusUpdate.payload.token,
        queuePosition: statusUpdate.payload.queuePosition,
        progress: null,
        error: null,
        url: statusUpdate.payload.url,
        filename: null,
        size: null,
        speed: null,
        created: statusUpdate.payload.created
      } as DownloadRequest;
      newReq = true;
    } else if (statusUpdate.status === "queued") {
      request = {
        ...request,
        queuePosition: statusUpdate.payload.queuePosition
      }
    } else if (statusUpdate.status === "downloading") {
      request = {
        ...request,
        filename: statusUpdate.payload.filename,
        queuePosition: null,
        size: statusUpdate.payload.size,
        progress: statusUpdate.payload.progress,
        speed: statusUpdate.payload.speed
      }
    } else if (statusUpdate.status === "done") {
      request = {
        ...request,
        filename: statusUpdate.payload.filename,
        queuePosition: null,
        size: statusUpdate.payload.size,
        progress: statusUpdate.payload.size,
        speed: null
      }
    } else if (statusUpdate.status === "error") {
      request = {
        ...request,
        error: statusUpdate.payload.error
      };
    }

    request.status = statusUpdate.status;

    if (request.status === 'accepted') request.status = 'queued';

    if (newReq) setRequests(prevState => [...prevState, request]);
    else setRequests(prevState => prevState.map(r => r.id === request.id ? request : r));
  }

  const fetchAllStatusCallback = useCallback(fetchAllStatus, [handleStatusUpdateCallback, requests]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAllStatusCallback();
    }, 500);
    return () => clearInterval(interval);
  }, [fetchAllStatusCallback]);

  function requestDownload(url: any) {
    DownloaderService.request(url).then(handleStatusUpdate);
  }

  function onSubmit({url}: any) {
    requestDownload(url);
  }

  const [cols] = useState(() => {
    return [
      {
        field: 'id',
        hide: true
      },
      {
        field: 'statusIcon',
        headerName: "",
        width: 30,
        align: 'center',
        renderCell: params => {
          let row = params.row as DownloadRequest;
          if (row.status === 'accepted') {
            return <FontAwesomeIcon icon={faChevronRight}/>;
          } else if (row.status === 'queued') {
            return <FontAwesomeIcon icon={faClock}/>;
          } else if (row.status === 'downloading') {
            return <FontAwesomeIcon icon={faArrowDown}/>;
          } else if (row.status === 'done') {
            return <FontAwesomeIcon icon={faCheck}/>;
          } else if (row.status === 'error') {
            return <FontAwesomeIcon icon={faTimes}/>;
          }
        }
      },
      {
        field: 'title',
        width: 300,
        headerName: t('downloader.header_title'),
        valueGetter: (params => {
          let row = params.row as DownloadRequest;
          return row.filename || row.url;
        })
      },
      {
        field: 'statusBar',
        headerName: t('downloader.header_statusBar'),
        width: 700,
        renderCell: params => {
          let row = params.row as DownloadRequest;
          if (row.status === 'accepted' || row.status === 'queued') {
            return <span>{t("downloader.n_on_queue", {queuePosition: row.queuePosition})}</span>
          } else if (row.status === 'downloading') {
            return <div className="w-100">
              {(row.size || 0) === 0 ? (
                <LinearProgress/>
              ) : (
                <LinearProgressWithLabel value={(row.progress || 0) / (row.size || 0) * 100}/>
              )}
            </div>
          } else if (row.status === 'done') {
            return <span>{t("downloader.download_complete")}</span>
          } else if (row.status === 'error') {
            return <span>{t("downloader.download_error", {error: row.error})}</span>
          }
        }
      },
      {
        field: 'actions',
        headerName: '',
        width: 50,
        align: 'center',
        renderCell: params => {
          let row = params.row as DownloadRequest;

          return <>
            <Button onClick={() => requestCancel(row)}>
              <FontAwesomeIcon icon={faTimes}/>
            </Button>
          </>
        }
      }
    ] as GridColumns
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="offset-2 col-8">
            <Controller name="url" control={control} defaultValue="" rules={{required: true, pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\\+.~#?&/=]*)/}}
                        render={({field: {onChange, onBlur, value}, fieldState: {error}}) => (
                          <TextField label={'Url'} onChange={onChange} onBlur={onBlur} value={value} error={!!error} helperText={error ? error.message : null} variant="standard" fullWidth/>
                        )}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="offset-3 col-6">
            <LoadingButton variant="outlined" type="submit" disabled={!formState.isValid} fullWidth>{t("downloader.submit")}</LoadingButton>
          </div>
        </div>
      </form>

      <div className="row mt-5" style={{height: '50vh'}}>
        <DataGrid
          columns={cols}
          rows={requests}
          hideFooterPagination={true}
          isRowSelectable={params => false}
        />
      </div>
    </>
  );
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

import React from "react";
import 'reflect-metadata';
import {Link} from "react-router-dom";
import logo from "../../../logo.svg";
import './Header.css'
import {HeaderUserData} from "./HeaderUserData";
import {useSelector} from "react-redux";
import {Store} from "../../../store/reducer";
import {useTranslation} from "react-i18next";
import {AuthService} from "../../../services/AuthService";
import ReactTooltip from "react-tooltip";

export function Header() {
  // const {loggedIn, user} = useSelector((state: Store) => state.AuthState);
  const {t} = useTranslation();

  if (window.location.host.startsWith("auth.")) return null;

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark text-light">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand brand">
          <img src={logo} alt="Infinitools"/>
          <span>Infinitools</span>
        </Link>
        <button className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <PermissionLink to="/admin/users" requiredPermission="infinitools.admin" text={t("pages.admin_users")}/>
            <PermissionLink to="/downloader" requiredPermission="infinitools" text={t("pages.downloader")}/>
          </ul>
          <HeaderUserData/>
        </div>
      </div>
    </nav>);
}

function PermissionLink(props: {to: string, requiredPermission: string, text: string}) {
  const {loggedIn, user} = useSelector((state: Store) => state.AuthState);
  const {t} = useTranslation();

  const isPermitted = loggedIn && AuthService.hasPermission(user, props.requiredPermission);
  const key = props.to.split("/").filter(x => !!x).join("-");
  return (
    <li className="nav-item">
      <Link data-tip data-for={'login-required-' + key} className={"nav-link" + (isPermitted ? "" : " text-muted pointer-default")} to={props.to}>{props.text}</Link>
      {isPermitted || (
        <ReactTooltip id={'login-required-' + key} type='dark' effect='solid'>
          <span>{t(loggedIn ? "header.permission_required_tooltip" : "header.login_required_tooltip")}</span>
        </ReactTooltip>
      )}
    </li>
  )
}
